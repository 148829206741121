import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import qs from "query-string";

export const AppWrapper: React.FC = () => {
	return (
		<QueryParamProvider
			adapter={ReactRouter6Adapter}
			options={{
				searchStringToObject: qs.parse,
				objectToSearchString: qs.stringify,
			}}
		>
			<Outlet />
		</QueryParamProvider>
	);
};
