import { useState } from "react";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";
import { Input } from "@alfalab/core-components/input";

import { API_ENDPOINT } from "@/lib/constants";

import image from "@/assets/images/onboarding.png";

import styles from "./Onboarding.module.css";

export const OnboardingView: React.FC = () => {
	const [userId, setUserId] = useState<string>("555");
	const [isLoading, setLoading] = useState(false);

	const submit = () => {
		setLoading(true);

		fetch(API_ENDPOINT, {
			method: "POST",
			// headers: {
			// 	"X-Auth-Token": "alfatoken",
			// },
			body: JSON.stringify({
				id: userId ? Number(userId) : 555, // Math.floor(randomNumber(1, 999)),
			}),
		})
			.then((res) => res.json())
			.then(({ data }) => {
				const url = new URL(data.url);

				location.href = `${url.pathname}${location.search}`;
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={styles["container"]}>
			<div>
				<Typography.TitleMobile tag="h1" view="medium" weight="bold">
					Афиша
				</Typography.TitleMobile>

				<div className={styles["content"]}>
					<div>
						<Typography.Text
							tag="p"
							defaultMargins={false}
							view="primary-medium"
						>
							Ходите на концерты, выставки, спектакли и яркие события города с
							кэшбэком 25%.
						</Typography.Text>

						<Typography.Text
							tag="p"
							view="primary-medium"
							color="secondary"
							className={styles["annotation"]}
						>
							Нажимая «За билетами», я даю согласие на переход в сервис партнёра
							банка.
						</Typography.Text>
					</div>
				</div>
			</div>

			<div>
				<div>
					<img
						width="100%"
						src={image}
						alt="onboarding"
						className={styles["image"]}
					/>
				</div>

				<Space fullWidth>
					<Input
						block
						size="m"
						label="User ID"
						value={userId}
						placeholder="555"
						onChange={(event) => setUserId(event.target.value)}
					/>

					<Button
						block
						view="primary"
						size="m"
						loading={isLoading}
						onClick={submit}
					>
						За билетами
					</Button>
				</Space>
			</div>
		</div>
	);
};
