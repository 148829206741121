import ReactDOM from "react-dom/client";

import { WithApp } from "./lib/app";
import { initErrorTracker } from "./lib/error";
import { Router } from "./Router";

initErrorTracker();

import "./index.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<WithApp>
		<Router />
	</WithApp>,
);
