import { useEffect } from "react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ENV, SENTRY_DSN } from "./constants";

export type ErrorType = "warning" | "message";

export class APIError extends Error {
	code: string;
	message: string;
	advice: string;
	system: string;
	type: "warning" | "message";
	error: number | string;

	constructor(config: {
		code: string;
		message: string;
		advice: string;
		system: string;
		type?: ErrorType;
		error: number;
	}) {
		super();

		this.code = config.code;
		this.advice = config.advice;
		this.system = config.system;
		this.message = config.message || "Произошла ошибка";
		this.type = config.type || "warning";
		this.error = config.error;
	}
}

export function initErrorTracker() {
	if (ENV === "prod") {
		Sentry.init({
			dsn: SENTRY_DSN,
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				}),
			],
			environment: ENV === "prod" ? "production" : "staging",
		});
	}
}
